const vanda = {
  colors: {
    primary: '#7B4A98',
    primaryMedium: '#3D1576',
    primaryStrong: '#3D1576',
    accent: '#F8AC3D',
    accentStrong: '#F8AC3D',
    gray600: '#424242',
    gray500: '#686868',
    gray400: '#9b9b9b',
    gray300: '#c7c7c7',
    gray200: '#e6e6e6',
    gray100: '#F0F0F0',
    white: '#FFFFFF',
    success: '#6ABD95',
    successStrong: '#2D9060',
    warning: '#F9C22E',
    error: '#ff0000',
    errorStrong: '#af351c',
    info: '#1D6BAE',
    infoStrong: '#0E3A60',
    infoLight: '#004A23',
    discountLight: '#FF6A4C',
    discount: '#FF4D29',
    discountStrong: '#AF351C',
    seatAvailableColor: '#260C4B',
    seatAvailableBorder: '#260C4B',
    seatOccupied: '#C7C7C7',
    seatOccupiedBorder: '#686868',
    seatOccupiedColor: '#686868',
    seatSelected: '#D9BFE9',
    seatSelectedBorder: '#260C4B',
    seatSelectedColor: '#260C4B',
    seatDisabledColor: '#9B9B9B',
    // Vanda colors
    headerBorder:
      'linear-gradient(to right, #e32185, #f8ac3d, #6abd95, #7b4a98, #1d6bae)',
    badge: '#D9BFE9',
    facebook: '#1877F2',
    facebookStrong: '#054290',
    whatsapp: '#4AC959',
    whatsappStrong: '#128E20',
  },
  elementColors: {
    busFill: 'success',
  },
  fonts: {
    primary: '"Poppins", "Helvetica-Neue", "Helvetica", sans-serif',
    secondary: '"Lato", "Helvetica-Neue", "Helvetica", sans-serif',
  },
  gradients: {
    headerBorder:
      'linear-gradient(to right, #e32185, #f8ac3d, #6abd95, #7b4a98, #1d6bae)',
  },
};

const rollBits = {
  colors: {
    primary: '#005DFF',
    primaryMedium: '#0041B3',
    primaryStrong: '#0041B3',
    accent: '#FF605A',
    accentStrong: '#FF2F27',
    gray600: '#232323',
    gray500: '#686868',
    gray400: '#9B9B9B',
    gray300: '#C7C7C7',
    gray200: '#E6E6E6',
    gray100: '#F0F0F0',
    white: '#FFFFFF',
    success: '#66BA5B',
    successStrong: '#448C3B',
    warning: '#E8B600',
    error: '#FF040D',
    errorStrong: '#C80007',
    info: '#DE9601',
    infoStrong: '#C58501',
    infoLight: '#F9C65B',
    discountLight: '#E98F26',
    discount: '#C86B11',
    discountStrong: '#A55509',
    seatAvailableColor: '#FF2F27',
    seatAvailableBorder: '#005DFF',
    seatOccupied: '#C7C7C7',
    seatOccupiedBorder: '#686868',
    seatOccupiedColor: '#9B9B9B',
    seatSelected: '#FF605A',
    seatSelectedBorder: '#FF2F27',
    seatSelectedColor: '#FFFFFF',
    seatDisabledColor: '#9B9B9B',
    // Roll&Bits colors
    headerBorder: 'linear-gradient(to right, #FF605A, #FF2F27)',
    badge: '#FFD1CF',
    facebook: '#1877F2',
    facebookStrong: '#054290',
    whatsapp: '#4AC959',
    whatsappStrong: '#128E20',
    primaryLight: '#D2F2FF',
  },
  elementColors: {
    busFill: 'success',
  },
  fonts: {
    primary: '"Poppins", "Helvetica-Neue", "Helvetica", sans-serif',
    secondary: '"Lato", "Helvetica-Neue", "Helvetica", sans-serif',
  },
  gradients: {
    headerBorder:
      'linear-gradient(to right, #e32185, #f8ac3d, #6abd95, #7b4a98, #1d6bae)',
  },
};

export default { vanda, rollBits };
