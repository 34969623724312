import mixpanel from 'mixpanel-browser';
import { screenSizes } from '@reservamos/elements';

export function getProduct() {
  const mediumSize = window.matchMedia(screenSizes.mediumAndUp);

  return mediumSize.matches ? 'web' : 'web-mobile';
}

export function init(token) {
  mixpanel.init(token, {
    loaded: (mixpanelInstance) => {
      /**
       * Assign mixpanel instance to window so the Search Widget can use it,
       * Also, dispatch an event to let the Search Widget know that mixpanel is ready
       * and it can init GrowthBook
       */
      window.mixpanel = mixpanelInstance;
      const event = new CustomEvent('MixpanelReady');
      window.dispatchEvent(event);
    },
  });
}

export function identify(id) {
  mixpanel.identify(id);
}

export function alias(id) {
  mixpanel.alias(id);
}

export function getDistinctId() {
  return mixpanel.get_distinct_id();
}

export function track(name, props) {
  const eventProps = { ...props, Product: getProduct() };

  // eslint-disable-next-line no-underscore-dangle
  if (!mixpanel.__loaded) {
    console.log('Mixpanel not initialized yet, waiting for it to be ready');
    window.addEventListener('MixpanelReady', () => {
      if (process.env.NODE_ENV === 'development') {
        console.log(`Mixpanel: ${name}`, eventProps);
      }
      mixpanel.track(name, eventProps);
    });

    return;
  }

  if (process.env.NODE_ENV === 'development') {
    console.log(`Mixpanel: ${name}`, eventProps);
  }
  mixpanel.track(name, eventProps);
}

export const people = {
  set(props) {
    mixpanel.people.set(props);
  },
};
