import React from 'react';
import PropTypes from 'prop-types';
import { mediaQueries } from '@reservamos/elements';
import { css, useTheme } from '@emotion/react';

const Container = ({
  children,
  hasShadow,
  hasBorderRadius,
  smallPadding,
  withoutPadding,
  withoutShadowMobile,
  hasBorderRadiusRightDesk,
  zIndex,
  margin,
  centeredContent,
}) => {
  const { colors } = useTheme();
  const shadowStyles = css`
    box-shadow: 2px 2px 5px ${colors.gray200};
  `;

  const borderRadiusStyles = css`
    border-radius: 5px;
  `;
  const zIndexStyles = css`
    z-index: ${zIndex};
  `;
  const marginStyles = css`
    margin: ${margin};
  `;
  const borderRadiusRight = css`
    ${mediaQueries.mediumAndUp} {
      border-radius: 0 15px 15px 0;
    }
  `;
  const centeredContentStyles = css`
    display: flex;
    align-items: center;
  `;

  const container = css`
    background-color: ${colors.white};
    padding: ${smallPadding ? `10px 20px` : `20px 30px`};
    width: 100%;
    ${hasShadow && shadowStyles};
    ${Boolean(zIndex) && zIndexStyles};
    ${hasBorderRadius && borderRadiusStyles};
    ${hasBorderRadiusRightDesk && borderRadiusRight};
    ${Boolean(margin) && marginStyles};
    ${withoutPadding && `padding: 0px;`};
    ${Boolean(centeredContent) && centeredContentStyles};

    ${mediaQueries.small} {
      padding: ${smallPadding ? `10px` : `20px 10px`};
      ${withoutPadding && `padding: 0px;`};
      ${withoutShadowMobile && `box-shadow: none;`};
    }
  `;

  return <div css={container}>{children}</div>;
};

Container.propTypes = {
  /** Container's content */
  children: PropTypes.node,
  /** Defines if Container has box-shadow on top */
  hasShadow: PropTypes.bool,
  hasBorderRadius: PropTypes.bool,
  /** Defines if Container has small padding */
  smallPadding: PropTypes.bool,
  /** Defines if Container has not padding */
  withoutPadding: PropTypes.bool,
  withoutShadowMobile: PropTypes.bool,
  hasBorderRadiusRightDesk: PropTypes.bool,
  zIndex: PropTypes.string,
  margin: PropTypes.string,
  /** Defines if Container has vertical centered content */
  centeredContent: PropTypes.bool,
};

Container.defaultProps = {
  children: null,
  hasBorderRadiusRightDesk: false,
  hasShadow: false,
  hasBorderRadius: false,
  smallPadding: false,
  withoutPadding: false,
  withoutShadowMobile: false,
  zIndex: '',
  margin: '',
  centeredContent: false,
};

export default Container;
