import React from 'react';
import PropTypes from 'prop-types';
import { Text, Spacing, Link } from '@reservamos/elements';

const FooterPolicies = ({ links, label }) => {
  return (
    <Spacing vertical alignItems="center" size="S">
      <Spacing>
        {links.map(({ href, text }) => (
          <Link
            key={href}
            href={href}
            type="grayStrong"
            text={text}
            newTab
            size="XS"
            weight="regular"
          />
        ))}
      </Spacing>
      <Text size="XS">{label}</Text>
    </Spacing>
  );
};

FooterPolicies.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  label: PropTypes.string.isRequired,
};

export default FooterPolicies;
