import React from 'react';
import { Icon, Spacing, Visibility } from '@reservamos/elements';
import assetUrl from '../../utils/assetUrl';
import Logo from '../atoms/Logo';
import FooterAppsUpdateLinks from './FooterAppsLinks';
import { vandaLinks, rollBitsLinks } from '../../constants/links';

const FooterLogo = () => {
  const links =
    process.env.NEXT_PUBLIC_BRAND === 'vanda' ? vandaLinks : rollBitsLinks;

  return (
    <Spacing
      justifyContent="space-between"
      alignItems="flex-start"
      mobileAlign="center"
    >
      <Spacing vertical size="M">
        <Logo src={assetUrl('main/logo.webp')} alt="Vanda Logo" removeWidth />
        <Spacing size="S">
          <a href={links.facebook} target="_blank" rel="noreferrer">
            <Icon
              type={assetUrl('social/facebookgray.webp')}
              alt="Facebook Icon"
            />
          </a>
          <a href={links.instagram} target="_blank" rel="noreferrer">
            <Icon
              type={assetUrl('social/instagramgray.webp')}
              alt="Instagram Icon"
            />
          </a>
        </Spacing>
      </Spacing>
      <Visibility type="showForMobileOnly">
        <FooterAppsUpdateLinks
          googleStoreLink={links.googleStore}
          appleStoreLink={links.appleStore}
        />
      </Visibility>
    </Spacing>
  );
};

export default FooterLogo;
