import React from 'react';
import { Text, Spacing, Visibility } from '@reservamos/elements';
import { css } from '@emotion/react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import assetUrl from '../../utils/assetUrl';
import FooterPaymentMethod from './FooterPaymentMethod';
import Container from '../atoms/Container';
import LayoutContainer from '../atoms/LayoutContainer';

const SmallFooter = () => {
  const { t } = useTranslation('common');
  const imageWrapper = css`
    width: 100px;
  `;
  return (
    <Container>
      <LayoutContainer>
        <Spacing isResponsive responsiveSize="L">
          <Spacing vertical fullWidth justifyContent="flex-end">
            <div css={imageWrapper}>
              <Image
                src={assetUrl('main/logo.webp')}
                width={100}
                height={20}
                alt="Roll & Bits logo"
              />
            </div>
            <Visibility type="hideForMobileOnly">
              <Text color="grayLight" size="XXS">
                {t('footer_rights_roll-bits')}
              </Text>
            </Visibility>
          </Spacing>
          <FooterPaymentMethod label={t('Safe Patyment')} />
          <Visibility type="showForMobileOnly">
            <Text color="grayLight" size="XXS" textAlign="center">
              {t('footer_rights_roll-bits')}
            </Text>
          </Visibility>
        </Spacing>
      </LayoutContainer>
    </Container>
  );
};

SmallFooter.propTypes = {};

SmallFooter.defaultProps = {};

export default SmallFooter;
