import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Script from 'next/script';
import Head from 'next/head';
import OneSignal from 'react-onesignal';
import { useRouter } from 'next/router';
import { ThemeProvider } from '@reservamos/elements';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { appWithTranslation } from 'next-i18next';
import assetUrl from '../utils/assetUrl';
import ErrorBoundary from '../errors/ErrorBoundary';
import * as fbq from '../lib/fpixel';
import * as gtm from '../lib/gtm';
import * as mixpanel from '../lib/mixpanel';
import '../styles/globals.css';
import themeToRender from '../styles/lib/themes/main';

const searchWidgetVersion = process.env.NEXT_PUBLIC_SEARCH_WIDGET_VERSION;

const client = new ApolloClient({
  uri: `${process.env.NEXT_PUBLIC_API_URL}`,
  cache: new InMemoryCache(),
});

const TribuApp = ({ Component, pageProps }) => {
  const router = useRouter();
  const theme =
    process.env.NEXT_PUBLIC_BRAND === 'vanda'
      ? themeToRender.vanda
      : themeToRender.rollBits;

  const initMixpanel = async () => {
    const mixpanelToken = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;
    mixpanel.init(mixpanelToken);
  };

  useEffect(() => {
    initMixpanel();
    if (process.env.NEXT_PUBLIC_BRAND === 'vanda') {
      OneSignal.init({
        appId: process.env.NEXT_PUBLIC_ONESIGNAL_APP_ID,
      });
    }
  }, []);

  useEffect(() => {
    const handleRouteChange = (url) => {
      if (process.env.NEXT_PUBLIC_BRAND === 'vanda') {
        fbq.pageview(url);
      }
      gtm.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      {process.env.NEXT_PUBLIC_BRAND === 'vanda' && (
        <>
          {/* Global Site Code Pixel - Facebook Pixel */}
          <Script
            id="facebook-jssdk"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `,
            }}
          />

          {/* Message Bird Script */}
          <Script
            id="messagebird-js"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
          var MessageBirdChatWidgetSettings = { 
            widgetId: '954e4b3b-cfb2-4490-82b3-993e8b64bbaa', 
            initializeOnLoad: true, 
          };
  
          !function(){"use strict";if(Boolean(document.getElementById("live-chat-widget-script")))
          console.error("MessageBirdChatWidget: Snippet loaded twice on page");else{var e,t;window.MessageBirdChatWidget={},
          window.MessageBirdChatWidget.queue=[];for(var i=["init","setConfig","toggleChat","identify","hide","on","shutdown"],
          n=function(){var e=i[d];window.MessageBirdChatWidget[e]=function(){for(var t=arguments.length,i=new Array(t),n=0;n<t;n++)i[n]=arguments[n];
          window.MessageBirdChatWidget.queue.push([[e,i]])}},d=0;d<i.length;d++)n();
          var a=(null===(e=window)||void 0===e||null===(t=e.MessageBirdChatWidgetSettings)||void 0===t?void 0:t.widgetId)||"",
          o=function(){var e,t=document.createElement("script");t.type="text/javascript",t.src="https://livechat.messagebird.com/bootstrap.js?widgetId=".concat(a),
          t.async=!0,t.id="live-chat-widget-script";var i=document.getElementsByTagName("script")[0];null==i||null===(e=i.parentNode)||void 0===e||e.insertBefore(t,i)};
          "complete"===document.readyState?o():window.attachEvent?window.attachEvent("onload",o):window.addEventListener("load",o,!1)}}();
          `,
            }}
          />
        </>
      )}

      {/* Google Tag Manager - Global base code */}
      <Script
        id="gtm-base"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${gtm.GTM_ID}');
          `,
        }}
      />

      {process.env.NEXT_PUBLIC_BRAND === 'vanda' && (
        /* Hotjar Tracking Code for https://vanda.app */
        <Script
          id="hotjar-tracking-code"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
        (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:2758785,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `,
          }}
        />
      )}

      {process.env.NEXT_PUBLIC_BRAND === 'roll-bits' && (
        /* Hotjar Tracking Code for https://roll-bits.vercel.app/ */
        <Script
          id="hotjar-tracking-code"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
        (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:3268974,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `,
          }}
        />
      )}

      {/* Adyen fingerprint */}
      <Script
        id="adyen-fingerprint"
        src="https://live.adyen.com/hpp/js/df.js?v=20171130"
        strategy="afterInteractive"
      />

      {/**
       *  Search Widget scripts
       */}
      {process.env.NEXT_PUBLIC_BRAND === 'roll-bits' && (
        <>
          <Script
            id="reservamos-search-jquery"
            src="https://code.jquery.com/jquery-2.2.4.min.js"
            strategy="beforeInteractive"
          />
          <Script
            id="search-widget-script"
            src={`https://widgets.reservamos.mx/search/${searchWidgetVersion}/search.js`}
            strategy="beforeInteractive"
          />
        </>
      )}

      <Head>
        <title>
          {process.env.NEXT_PUBLIC_BRAND === 'vanda' ? 'Vanda' : 'Roll & Bits'}
        </title>
        <meta
          name="description"
          content={
            process.env.NEXT_PUBLIC_BRAND === 'vanda' ? 'Vanda' : 'Roll & Bits'
          }
        />
        <link rel="icon" href={assetUrl('main/favicon.ico')} />

        <meta
          name="facebook-domain-verification"
          content="w413gvpgral6odyc1qw0q5qqb6dgey"
        />
      </Head>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <ErrorBoundary>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Component {...pageProps} />
          </ErrorBoundary>
        </ThemeProvider>
      </ApolloProvider>
    </>
  );
};

TribuApp.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.shape({
    initialApolloState: PropTypes.shape({}),
  }).isRequired,
};

export default appWithTranslation(TribuApp);
