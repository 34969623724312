export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;

export const pageview = (url) => {
  window.dataLayer.push({
    event: 'pageview',
    page: url,
  });
};

export const purchase = (
  transactionId,
  value,
  item,
  purchaserPhone,
  purchaserEmail
) => {
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'purchase',
    ecommerce: {
      transaction_id: transactionId,
      value,
      currency: 'MXN',
      items: [
        {
          item_name: item.name,
          item_id: item.id,
          price: item.price,
          quantity: 1,
          discount: item.discount,
          items: [{ item_id: item.id, item_name: item.name }],
        },
      ],
    },
    purchaserEmail,
    purchaserPhone,
  });
};

export const addToCart = (name, id, price, quantity) => {
  // Measure when a product is added to a shopping cart
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: 'add_to_cart',
    ecommerce: {
      value: price,
      currency: 'MXN',
      item_name: name,
      item_id: id,
      quantity,
      items: [{ item_id: id, item_name: name }],
    },
  });
};

// TODO: Refactor the props naming to match Vanda context
export const beginCheckout = (id, name, price, quantity) => {
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: 'begin_checkout',
    transaction_id: id,
    ecommerce: {
      currency: 'MXN',
      value: price,
      item_name: name,
      item_id: id,
      quantity,
      items: [{ item_id: id, item_name: name }],
    },
  });
};

export const viewContent = (id, name, price, quantity) => {
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: 'view_content',
    ecommerce: {
      currency: 'MXN',
      value: price,
      item_name: name,
      item_id: id,
      quantity,
    },
  });
};

export const addPaymentInfo = () => {
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({ event: 'add_payment_info' });
};
