import React from 'react';
import { Text, Spacing, Button, Link } from '@reservamos/elements';
import Image from 'next/image';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

const ErrorInfo = ({
  imageSrc,
  imageAlt,
  title,
  buttonText,
  children,
  isReload,
  linkText,
  linkHref,
  isBack,
  params,
}) => {
  const router = useRouter();
  const handleClick = () => {
    if (!isReload && !isBack) {
      router.push('/');
    } else if (isReload) {
      router.reload(window.location.pathname);
    } else {
      router.push(`/payment${params}`);
    }
  };
  return (
    <Spacing
      vertical
      alignItems="center"
      justify-content="center"
      textAlign="center"
    >
      <Image src={imageSrc} width={170} height={170} alt={imageAlt} />
      <Text size="L" weight="bold" color="primaryStrong">
        {title}
      </Text>
      {children}
      {Boolean(buttonText) && (
        <Button
          text={buttonText}
          variant="primary"
          minHeight="50px"
          onClick={() => handleClick()}
        />
      )}
      {Boolean(linkText) && (
        <Link text={linkText} type="accent" href={linkHref} weight="regular" />
      )}
    </Spacing>
  );
};

ErrorInfo.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  children: PropTypes.node.isRequired,
  isReload: PropTypes.bool,
  linkText: PropTypes.string,
  linkHref: PropTypes.string,
  isBack: PropTypes.bool,
  params: PropTypes.string,
};

ErrorInfo.defaultProps = {
  buttonText: false,
  title: '',
  isReload: false,
  linkText: '',
  linkHref: '',
  isBack: false,
  params: '',
};

export default ErrorInfo;
