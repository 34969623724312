import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { mediaQueries } from '@reservamos/elements';

const LayoutContainer = ({ children, paddingMobile }) => {
  const layoutContainer = css`
    margin: 0 auto;
    max-width: 1100px;
    padding: 0;
    width: 100%;
    ${mediaQueries.small} {
      ${paddingMobile && `padding: 0px 30px;`};
    }
  `;

  return <div css={layoutContainer}>{children}</div>;
};

LayoutContainer.propTypes = {
  /** LayoutContainer's content */
  children: PropTypes.node,
  paddingMobile: PropTypes.bool,
};

LayoutContainer.defaultProps = {
  children: null,
  paddingMobile: false,
};

export default LayoutContainer;
