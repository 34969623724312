import React, { Component } from 'react';
import * as Sentry from '@sentry/nextjs';
import PropTypes from 'prop-types';
import ErrorView from '../pages/errorView';
import GraphqlError from './GraphqlError';

function handleGraphqlError(error) {
  Sentry.captureException(GraphqlError(error));
}

class ErrorBoundary extends Component {
  constructor(...props) {
    super(...props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    // React Error Boundary here allows us to set state flagging the error (and
    // later render a fallback UI).
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const { graphQLErrors } = error;

    if (graphQLErrors) {
      graphQLErrors.forEach(handleGraphqlError);
    } else {
      Sentry.captureException(error, { errorInfo });
    }
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) return <ErrorView />;

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
