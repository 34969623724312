import React from 'react';
import Image from 'next/image';
import { mediaQueries } from '@reservamos/elements';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

const Logo = ({ alt, src, removeWidth }) => {
  const image = css`
    height: 30px;
    display: inline-block;
    object-fit: contain;
    ${removeWidth || 'width: 145px'};

    ${mediaQueries.xsmall} {
      max-width: 100px;
    }
  `;

  return (
    <Image
      src={src}
      css={image}
      alt={alt}
      height={35}
      width={!removeWidth ? 145 : 100}
    />
  );
};

Logo.propTypes = {
  /** Alt text for logo */
  alt: PropTypes.string.isRequired,
  /** Logo url */
  src: PropTypes.node.isRequired,
  /** Remove the width of the logo */
  removeWidth: PropTypes.bool,
};

Logo.defaultProps = {
  removeWidth: false,
};

export default Logo;
