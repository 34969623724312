import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FlatButton, Spacing, mediaQueries } from '@reservamos/elements';
import { css, useTheme } from '@emotion/react';

const linkName = {
  routes: {
    text: 'Rutas',
    href: '#routes',
  },
  why: {
    text: '¿Por qué Vanda?',
    href: '#whyVanda',
  },
  safety: {
    text: 'Protocolos de Bioseguridad',
    href: '#safety',
  },
  faq: {
    text: 'Preguntas Frecuentes',
    href: '#faq',
  },
};

const Navigation = ({ links }) => {
  const [sectionSelected, setSectionSelected] = useState(null);
  const { colors } = useTheme();
  const container = css`
    background-color: ${colors.white};
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
    padding: 0 10px;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }
  `;

  const linkContainer = css`
    border-bottom: 3px solid transparent;
    padding: 5px 10px;
    ${mediaQueries.small} {
      padding: 10px;
    }
  `;

  const linkBorder = css`
    border-bottom: 3px solid ${colors.accent};
  `;

  return (
    <div css={container}>
      <Spacing size="S">
        {links.map((link) => (
          <div
            css={[
              linkContainer,
              sectionSelected === linkName[link].text ? linkBorder : null,
            ]}
            key={linkName[link].text}
          >
            <FlatButton
              size="S"
              text={linkName[link].text}
              type="primaryStrong"
              href={linkName[link].href}
              weight={
                sectionSelected === linkName[link].text ? 'bold' : 'regular'
              }
              onClick={() => setSectionSelected(linkName[link].text)}
              textDecorationLine="none"
            />
          </div>
        ))}
      </Spacing>
    </div>
  );
};

Navigation.propTypes = {
  /** List for links */
  links: PropTypes.arrayOf(
    PropTypes.oneOf(['itinerary', 'why', 'safety', 'faq'])
  ).isRequired,
};

export default Navigation;
