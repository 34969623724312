import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import { css, useTheme } from '@emotion/react';
import { Text, mediaQueries, Spacing } from '@reservamos/elements';
import assetUrl from '../../utils/assetUrl';

const FooterPaymentMethod = ({ label }) => {
  const { colors } = useTheme();

  const wrapper = css`
    position: relative;
  `;
  const iconsWrapper = css`
    border-radius: 10px;
    border: 1.5px solid ${colors.primary};
    min-width: 400px;
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;

    ${mediaQueries.small} {
      min-width: 100%;
      justify-content: center;
      padding: 15px;
    }
  `;
  const text = css`
    position: absolute;
    left: -24px;
    background: ${colors.white};
    padding: 5px 10px;
    ${mediaQueries.small} {
      top: -17px;
      left: 14px;
    }
  `;
  const imgPayment = css`
    padding: 5px;
    width: 56px;
  `;

  return (
    <div css={wrapper}>
      <div css={iconsWrapper}>
        <div css={text}>
          <Spacing alignItems="center" size="S">
            <Image
              src={assetUrl('icons/insurance.webp')}
              width={20}
              height={23}
              alt="insurance"
            />
            <Text size="XS" color="primary" weight="bold">
              {label}
            </Text>
          </Spacing>
        </div>
        <div css={imgPayment}>
          <Image
            src={assetUrl('icons/visa.webp')}
            width={50}
            height={15}
            alt="Visa"
          />
        </div>
        <div css={imgPayment}>
          <Image
            src={assetUrl('icons/mastercard.webp')}
            width={32}
            height={22}
            alt="Mastercard"
          />
        </div>
        <div css={imgPayment}>
          <Image
            src={assetUrl('icons/american.webp')}
            width={32}
            height={22}
            alt="Amex"
          />
        </div>
        <div css={imgPayment}>
          <Image
            src={assetUrl('icons/paypal_original.webp')}
            width={55}
            height={15}
            alt="Paypal"
          />
        </div>
        <div css={imgPayment}>
          <Image
            src={assetUrl('icons/oxxo.webp')}
            width={50}
            height={25}
            alt="Oxxo"
          />
        </div>
      </div>
    </div>
  );
};
FooterPaymentMethod.propTypes = {
  label: PropTypes.string.isRequired,
};

export default FooterPaymentMethod;
