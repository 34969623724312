import React from 'react';
import PropTypes from 'prop-types';
import { Spacing, Visibility } from '@reservamos/elements';
import { useTranslation } from 'next-i18next';
import LayoutContainer from '../atoms/LayoutContainer';
import Container from '../atoms/Container';
import FooterLogo from './FooterLogo';
import FooterPolicies from './FooterPolicies';
import FooterContact from './FooterContact';
import { vandaLinks, rollBitsLinks } from '../../constants/links';
import FooterAppsLinks from './FooterAppsLinks';

const Footer = ({ hideLinks }) => {
  const { t } = useTranslation('common');

  const links =
    process.env.NEXT_PUBLIC_BRAND === 'vanda' ? vandaLinks : rollBitsLinks;

  const linksInfo = [
    {
      text: links.phoneText,
      href: links.phoneHref,
      hideLinks: true,
    },
    { text: links.emailText, href: links.email, hideLinks: false },
  ];

  const linksPolicies = [
    {
      text: links.policiesText,
      href: links.policies,
    },
    {
      text: t('footer', { context: 'privacyLinkText' }),
      href: links.privacy,
    },
    {
      text: t('footer', { context: 'conditionsLinkText' }),
      href: links.termsAndConditions,
    },
    {
      text: t('footer', { context: 'billingLinkText' }),
      href: links.billing,
      hideLinks: false,
    },
  ];
  const linksContact = hideLinks
    ? linksInfo
    : linksInfo.filter((route) => !route.hideLinks);
  return (
    <footer>
      <Container>
        <LayoutContainer>
          <Spacing
            justifyContent="space-between"
            alignItems="center"
            isResponsive
            fullWidth
          >
            <Spacing vertical size="M" responsiveFullWidth isResponsive>
              <FooterLogo />
              <FooterContact
                label={t('footer', { context: 'contact' })}
                links={linksContact}
              />
            </Spacing>
            <FooterPolicies
              links={linksPolicies}
              label={t('footer_rights', {
                context: process.env.NEXT_PUBLIC_BRAND,
              })}
            />
            <Visibility type="hideForMobileOnly">
              <FooterAppsLinks
                googleStoreLink={links.googleStore}
                appleStoreLink={links.appleStore}
              />
            </Visibility>
          </Spacing>
        </LayoutContainer>
      </Container>
    </footer>
  );
};
Footer.propTypes = {
  hideLinks: PropTypes.bool,
};

Footer.defaultProps = {
  hideLinks: false,
};
export default Footer;
