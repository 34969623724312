import React from 'react';
import PropTypes from 'prop-types';
import { Spacing, Link } from '@reservamos/elements';

const FooterContact = ({ links }) => {
  return (
    <Spacing size="XS">
      {links.map(({ href, text }) => (
        <Link
          key={text}
          href={href}
          type="grayStrong"
          text={text}
          newTab
          size="S"
        />
      ))}
    </Spacing>
  );
};

FooterContact.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default FooterContact;
